<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <WindowTitleBar title="Purchase Creation" @loadData="loadData" @close_window="closeThis">{{ setTitle() }}
      </WindowTitleBar>

      <div class="card-body">

        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Customer</label>
              <select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.supplier_id" @change="enableAddButton">
                <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Type</label>
              <select class="form-control select" required="" v-if="voucher" v-model="voucher.type"
                      @change="enableAddButton">
                <option value="301">B2B</option>
                <option value="302">B2C</option>
                <option value="303">Composition</option>
                <option value="304">Exempted</option>
                <option value="305">Imported</option>
                <option value="306">NonGST</option>
                <option value="307">In Eligible</option>
                <option value="308">RCM</option>

                <option value="300">Others</option>
              </select>
            </div>
          </div>

          <div class="col-md-3">

          </div>
          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label  font-weight-semibold">A/c Date</label>
              <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date" placeholder="Ac Date">
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class=" control-label font-weight-semibold">Invoice No</label>
              <input type="text" class="form-control" placeholder="Invoice No" minlength="0" maxlength="30"
                     v-if="voucher" v-model="voucher.ref_no">
            </div>
          </div>


          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label  font-weight-semibold">Invoice Date</label>
              <input id="txtrefdate" type="date" class="form-control" v-if="voucher" v-model="voucher.ref_date"
                     placeholder="Invoice Date">
            </div>
          </div>

        </div>


        <div class="row">
          <!--  Detail Table -->
          <div class="table-responsive">

            <table id="mytable" class="table table-no-bordered">
              <thead style="background-color: lightgrey">
              <tr>
                <th style="width:50px;">S.No</th>
                <th>Item</th>
                <th style="width:100px; text-align: right;">GST%</th>
                <th style="width:100px; text-align: right;">Rate</th>
                <th style="width:75px; text-align: right;">Qty</th>
                <th style="width:50px; text-align: right;">Discount</th>
                <th style="width:100px; text-align: right;">Taxable</th>
                <th style="width:100px; text-align: right;">IGST</th>
                <th style="width:100px; text-align: right;">CGST</th>
                <th style="width:100px; text-align: right;">SGST</th>
                <th style="width:150px; text-align: right;">Net Amount</th>
                <th style="width: 30px;">Action</th>
              </tr>

              </thead>
              <tbody v-for="(detail,index) in voucher.list">
              <InvoiceDetail :state="voucher.supplier.state_cd" :vchtype="voucher.type" :items="detailItems"
                             :myindex="index" :myrow="detail" v-on:onrow_change="onrow_change"
                             v-on:onRemoveRow="removeRow"></InvoiceDetail>
              </tbody>
              <tfoot>
              <tr>
                <td>
                  <button id="btnAddRow" type="button"
                          class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple"
                          @click="addRow" :disabled="addRowButtonDisabled">
                    <i class="icon-plus3"></i>
                  </button>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right"><span style="text-align: right;">{{ rowQtyTotal }}</span></td>
                <td></td>
                <td class="text-right">&#8377;<span style="text-align: right;">{{
                    indianFormat(rowTaxableTotal)
                  }}</span></td>
                <td class="text-right">&#8377;<span style="text-align: right;">{{ indianFormat(rowIgstTotal) }}</span>
                </td>
                <td class="text-right">&#8377;<span style="text-align: right;">{{ indianFormat(rowCgstTotal) }}</span>
                </td>
                <td class="text-right">&#8377;<span style="text-align: right;">{{ indianFormat(rowSgstTotal) }}</span>
                </td>
                <td class="text-right">&#8377;<span style="text-align: right;">{{ indianFormat(rowAmountTotal) }}</span>
                </td>

                <td></td>

              </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <!-- / Detail Table -->


        <div class="row" style="padding: 3px;">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration" v-if="voucher"
                        v-model="voucher.remarks"> </textarea>
            </div>
          </div>


          <div class="col-md-6">
            <h3 style="font-size: 48px;color: blue">
              <!--voucher.gross_amt-->
              &#8377;<span style="padding-right: 15px;">{{
                indianFormat(rowAmountTotal + parseFloat(voucher.round_off) + parseFloat(voucher.tcs_amt))
              }}</span>
            </h3>
          </div>

          <div class="col-md-3 text-right" style="padding: 3px;">

            <div class="form-group row" style="padding: 3px;">
              <label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>
              <div class="col-md-8" style="padding: 3px;">
                <input id="txtroundoff" type="number" class="form-control text-right" style="padding: 3px;"
                       placeholder="Round Off Value" v-if="voucher" v-model="voucher.round_off">
              </div>
            </div>


            <div class="form-group row" style="padding: 3px;">
              <label class="col-form-label col-md-4 font-weight-semibold">TCS</label>
              <div class="col-md-8">
                <input id="txttcs" type="number" class="form-control text-right" style="padding: 3px;"
                       placeholder="TCS Value" v-if="voucher" v-model="voucher.tcs_amt">
              </div>
            </div>

            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active"
                    v-shortkey="['ctrl', 'alt', 's']" @shortkey="saveVoucher" @click="saveVoucher">Save
            </button>
            <button id="btnclose" type="button" class="btn btn-default" data-dismiss="" @click="clear">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>

//https://www.vuetoolbox.com/projects/vue-shortkey
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import numeral from 'numeral'
import InvoiceDetail from './InvoiceDetailRow.vue'
import { store } from '@/store/store.js'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'InvoiceForm',
  components: {
    WindowTitleBar,
    InvoiceDetail
  },
  store,
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"tcs_amt":0,"net_amt":0,"list":[]}')
    }
  },
  beforeMount () {
    const self = this
    const requestOptions = {
      method: 'GET',
      mode: 'cors',
      headers: userService.authHeader()
    }

    if (this.myvoucher.id > 0) {
      var data = []
      const request = async () => {
        const response = await fetch(`${process.env.VUE_APP_ROOT_API}api/item`, requestOptions)

        const json = await response.json()
        data = json.data
        if (_.isArray(data)) {
          data.forEach(function (itm) {
            self.$data.items.set(itm.id, itm)
          })
        }
        this.voucher = this.myvoucher
        this.sumRowAmountTotal()
        // this.voucher.round_off = this.voucher.round_off.toFixed(2);
      }
      request()
    }
  },
  data () {
    return {
      addRowButtonDisabled: true,
      readonly: false,
      ledgers: [],
      detailItems: [],
      items: new Map(),
      rowQtyTotal: 0.0,
      rowAmountTotal: 0.0,
      rowIgstTotal: 0.0,
      rowCgstTotal: 0.0,
      rowSgstTotal: 0.0,
      rowTaxableTotal: 0.0,
      voucher: JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"tcs_amt":0,"net_amt":0,"list":[]}'),
    }
  },
  created () {
    const self = this

    self.$data.voucher.doc_date = moment().format('YYYY-MM-DD')
    self.$data.voucher.ref_date = moment().format('YYYY-MM-DD')
  },
  mounted () {
    const self = this;
    self.loadData();

    if (self.$data.voucher.doc_date === '0001-01-01') {
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    }

    $('#cmbledger').focus();
  },
  methods: {
    indianFormat (val) {
      return parseFloat(val).toFixed(2)
    },
    setTitle () {
      return this.voucher.id == 0 ? 'Purchase Creation' : 'Doc No: ' + this.voucher.doc_no + '  Purchase Updation'
    },
    closeThis () {
      this.$emit('purchase_window_closed')
      if (this.voucher.id > 1) {
        this.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"tcs_amt":0,"net_amt":0,"list":[]}')
      } else {
        this.$router.push('/')
      }
    },
    enableAddButton () {
      const self = this

      this.ledgers.forEach(function (ldgr) {

        if (self.$data.voucher.supplier_id == ldgr.id) {
          self.$data.voucher.supplier = JSON.parse(JSON.stringify(ldgr))
        }
      })

      this.$data.voucher.list = []
      this.$data.addRowButtonDisabled = (this.$data.voucher.supplier_id > 0 && this.$data.voucher.type == 0)
    },
    addRow () {
      try {
        const self = this
        self.$data.voucher.list.push(JSON.parse('{"idx":0,"id":0,"order_id":1,"workorder_id":1,"hsn":"","item":{"id":0,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"rate":0,"qty":0,"qty_txt":"","weight":0,"discount":0,"taxable_amt":0,"round_off":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}'))

        setTimeout(function () {
          $('tbody>tr').last().find('td:eq(1) select').focus()
        }, 99)

      } catch (e) {
        alert(e)
      }
    },
    calculate_gst (detail) {

      const self = this
      let taxrate = 0

      if (this.$data.voucher.type == 301 || this.$data.voucher.type == 307) {
        taxrate = detail.gst_rate
      }

      let tax_value = Math.round(detail.qty * detail.rate * (taxrate / 100) * 100)
      if (tax_value % 2 !== 0) {
        tax_value++
      }
      tax_value = tax_value / 100

      detail.tax_amt = tax_value
      if (self.$data.voucher.supplier.state_cd == 0 || self.$data.voucher.supplier.state_cd == store.state.user.state_cd) {
        detail.igst_amt = 0
        detail.sgst_amt = tax_value / 2
        detail.cgst_amt = tax_value / 2
      } else {
        detail.igst_amt = tax_value
        detail.sgst_amt = 0
        detail.cgst_amt = 0
      }
    },
    onrow_change (rowIndex, colIndex) {
      const self = this
      let tax_value = 0

      let detail = self.$data.voucher.list[rowIndex]
      self.$data.rowQtyTotal = 0.0
      self.$data.rowAmountTotal = 0.0
      self.$data.rowIgstTotal = 0.0
      self.$data.rowCgstTotal = 0.0
      self.$data.rowSgstTotal = 0.0
      self.$data.rowTaxableTotal = 0.0
      self.$data.voucher.list.forEach(function (detail) {

        self.$data.rowQtyTotal += parseFloat(detail.qty)
        self.$data.rowAmountTotal += parseFloat(detail.net_amt)
        self.$data.rowIgstTotal += parseFloat(detail.igst_amt)
        self.$data.rowSgstTotal += parseFloat(detail.sgst_amt)
        self.$data.rowCgstTotal += parseFloat(detail.cgst_amt)
        self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt)
      })
    },
    removeRow (index) {
      if (index > -1) {
        this.$data.voucher.list.splice(index, 1)
        this.sumRowAmountTotal()
      }
    },
    sumRowAmountTotal () {
      const self = this

      self.$data.rowQtyTotal = 0.0
      self.$data.rowAmountTotal = 0.0
      self.$data.rowIgstTotal = 0.0
      self.$data.rowCgstTotal = 0.0
      self.$data.rowSgstTotal = 0.0
      self.$data.rowTaxableTotal = 0.0
      self.$data.voucher.list.forEach(function (detail) {
        self.$data.rowQtyTotal += parseFloat(detail.qty)
        self.$data.rowAmountTotal += parseFloat(detail.net_amt)
        self.$data.rowIgstTotal += parseFloat(detail.igst_amt)
        self.$data.rowSgstTotal += parseFloat(detail.sgst_amt)
        self.$data.rowCgstTotal += parseFloat(detail.cgst_amt)
        self.$data.rowTaxableTotal += parseFloat(detail.taxable_amt)
      })
    },
    clear () {
      $('#btnSave').prop('disabled', false)
      const self = this
      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"tcs_amt":0,"net_amt":0,"list":[]}')
      self.$data.voucher.list = []
      self.$data.rowAmountTotal = 0.0
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD')
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD')
      self.$data.addRowButtonDisabled = true
      self.$data.rowQtyTotal = 0.0
      self.$data.rowAmountTotal = 0.0
      self.$data.rowIgstTotal = 0.0
      self.$data.rowCgstTotal = 0.0
      self.$data.rowSgstTotal = 0.0
      self.$data.rowTaxableTotal = 0.0
      $('#cmbledger').focus()
    },
    loadData () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      //fetch the Ledgers
      self.$data.ledgers = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/suppliers`, requestOptions).then(userService.handleResponse).then(function (resp) {

        if (resp.ok) {
          if (_.isArray(resp.data)) {

            console.log(JSON.stringify(resp.data));

            self.$data.ledgers = resp.data;
            self.$data.ledgers.forEach(function (ldgr) {
              // ldgr.state_cd = ldgr.address.state_cd;
            })
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' });
      })

      if (self.$data.items.size == 0) {
        //Fetch Detail Ledgers
        self.$data.detailItems = [];
        self.$data.items = new Map();
        fetch(`${process.env.VUE_APP_ROOT_API}api/items`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {

            if (_.isArray(resp.data)) {
              self.$data.detailItems = resp.data;

              // console.log(self.$data.detailItems);

              self.$data.detailItems.forEach(function (itm) {
                Object.freeze(itm);
                self.$data.items.set(itm.id, itm);
              })
            }
            $('.select-search').select2();

          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error' });
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error' });
        })
      }
    },
    saveVoucher () {
      const self = this

      if (parseFloat(self.$data.voucher.tcs_amt) < 0.0) {
        swal({ title: 'Info', text: 'Invalid TCS Amount', type: 'error' })
        return
      }

      try {

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher))

        myvoucher.finyear = self.$store.state.user.finyear
        myvoucher.status = 'Active'
        myvoucher.cmp_id = self.$store.state.user.company.id
        myvoucher.brn_id = self.$store.state.user.branch.id
        myvoucher.series = 302
        myvoucher.supplier_id = parseInt(self.$data.voucher.supplier_id)
        myvoucher.type = parseInt(self.$data.voucher.type)
        myvoucher.eway_date = moment().format('YYYY-MM-DD')
        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD')
        myvoucher.ref_date = moment(self.$data.voucher.ref_date).format('YYYY-MM-DD')
        // myvoucher.eway_bil_date = moment(self.$data.voucher.ref_date).format('YYYY-MM-DD');
        myvoucher.round_off = parseFloat(self.$data.voucher.round_off)
        myvoucher.gross_amt = self.$data.rowAmountTotal
        myvoucher.net_amt = myvoucher.gross_amt + myvoucher.round_off
        myvoucher.tcs_amt = parseFloat(self.$data.voucher.tcs_amt)
        myvoucher.process_id = 25  //Purchase

        myvoucher.list.forEach(function (detail) {

          detail.gst_rate = parseFloat(detail.item.group.gstrate);
          detail.qty_txt = detail.qty.toString();
          detail.qty = parseFloat(detail.qty);
          detail.rate = parseFloat(detail.rate);
          detail.taxable_amt = parseFloat(detail.taxable_amt);

          detail.igst_amt = parseFloat(detail.igst_amt);
          detail.sgst_amt = parseFloat(detail.sgst_amt);
          detail.cgst_amt = parseFloat(detail.cgst_amt);

          detail.net_amt = parseFloat(detail.net_amt);
        })

        const requestOptions = {
          method: (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        $('#btnSave').prop('disabled', true);
        fetch(`${process.env.VUE_APP_ROOT_API}api/purchase`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.$emit('purchase_saved', resp.data);

            swal({
              title: 'Great', type: 'success', text: resp.msg, onClose: () => {
                $('#cmbledger').focus();
              }, timer: 1500
            })
            $('#mycard').unblock();

            self.clear();
            self.$data.voucher.list = [];

          } else {
            $('#btnSave').prop('disabled', false);
            $('#mycard').unblock();
            swal({ title: 'Oops', text: resp.msg, type: 'error' });
          }
        }).catch(function (err) {
          $('#mycard').unblock();
          $('#btnSave').prop('disabled', false);
          swal({ title: 'Oops', text: err.toString(), type: 'error' });
        })

      } catch (e) {

      } finally {

      }

    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a !important;
  }
</style>
